<template>
    <div class="item">
        <el-cascader style="flex: 1" size="mini" placeholder="选择分类名" v-model="form.pid" :options="options"></el-cascader>
        <el-input style="flex: 1" size="mini" v-model="form.content" placeholder="功能模块"></el-input>
        <div>
            <span>是否支持: </span>
            <el-switch v-model="form.support" active-color="#7781f1" inactive-color="#ff4949" :active-value="1" :inactive-value="0"> </el-switch>
        </div>
        <el-input style="flex: 1" size="mini" v-model="form.reason" placeholder="说明"></el-input>
        <el-switch
            v-model="form.status"
            active-color="#7781f1"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            active-text="显示"
            inactive-text="隐藏"
        >
        </el-switch>
        <el-tooltip effect="dark" content="新增一行" placement="top">
            <i class="el-icon-circle-plus-outline" style="font-size: 24px; color: #7781f1" @click="$emit('add')"></i>
        </el-tooltip>
        <el-tooltip effect="dark" content="删除该行" placement="top">
            <i class="el-icon-remove-outline" style="font-size: 24px; color: #7781f1" @click="$emit('del', form.key)"></i>
        </el-tooltip>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {
        options: Array,
        form: Object,
    },
};
</script>

<style lang="less" scoped>
.item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 20px 0;
    /deep/.el-switch__label.is-active {
        color: #7781f1;
    }
    & > div {
        margin-right: 10px;
    }
}
</style>
