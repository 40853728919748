<!-- 成功简介 -->
<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>定价</el-breadcrumb-item>
                    <el-breadcrumb-item>规格列表</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="s == 1">列表</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="s == 2">添加</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="s == 3">编辑</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_headerSecondary">
                <div></div>
                <div class="_add">
                    <button @click="add" v-show="s == 1">添加</button>
                </div>
            </div>
            <!-- 列表 -->
            <div class="page_content" v-show="s == 1">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column prop="name" align="center" label="分类名"> </el-table-column>
                    <el-table-column prop="bk_num" align="center" label="板块总数"></el-table-column>
                    <el-table-column prop="gn_num" align="center" label="功能总数"></el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <div class="_operate">
                                <button @click="edit(scope.row)">编辑</button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 添加 -->
            <add v-show="s == 2" @stop="stop" :dataAll="dataAll"></add>
            <!-- 编辑 -->
            <edit v-show="s == 3" @stop="stop" :dataAll="dataAll" :dataEdit="dataEdit" :pid="pid"></edit>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import add from "./add.vue";
import edit from "./edit.vue";

export default {
    components: { add, edit },
    props: {},
    data() {
        return {
            tableData: [
                {
                    // name: '一级 > 二级',
                    // bk_num: 0,
                    // gn_num: 0,
                },
            ],
            // 完整数据
            dataAll: [],
            // 编辑的数据
            dataEdit: [],
            // 点击编辑时传过来的二级id
            pid: 0,
            // 1列表 2添加 3编辑
            s: 1,
        };
    },
    mounted() {
        this.onLoading();
    },
    created() {},
    methods: {
        async getData() {
            // 所有数据一二三级
            let { data } = await axios.get("/api/price/show");
            return data;
        },
        async onLoading() {
            let dataAll = await this.getData();
            this.dataAll = dataAll;
            let arr = [];
            dataAll.forEach(el => {
                let num = 0;
                el.second.forEach(e => {
                    num += e.third.length;
                });
                el.second.forEach((e, i, a) => {
                    arr.push({
                        ...e,
                        name: `${el.name} > ${e.name}`,
                        bk_num: el.second.length,
                        gn_num: num,
                    });
                    // e.third.forEach(ele => {});
                });
            });
            this.tableData = arr;
        },
        // 删除一项
        del(id) {
            this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                axios.delete("/api/price/tdelete", { params: { id } }).then(() => {
                    this.$message.success("删除成功");
                    this.onLoading();
                });
            });
        },
        // 添加
        add() {
            this.s = 2;
        },
        // 从添加或编辑取消返回至列表
        stop() {
            this.s = 1;
            this.onLoading();
        },
        // 编辑
        edit(row) {
            this.pid = row.id;
            this.dataEdit = row.third.map(el => {
                // firstId 一级分类的id
                return { ...el, firstId: row.pid };
            });
            this.s = 3;
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
}
</style>
