<template>
    <div class="add">
        <div class="list">
            <template v-for="el in list">
                <addItem :options="options" :key="el.key" :form="el" @add="add(el)" @del="del"></addItem>
            </template>
        </div>
        <!-- 悬浮按钮 -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <VyIcon name="取消" scale="4" @click.native="no"></VyIcon>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import qs from "qs";
import addItem from "./add_item.vue";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { addItem, VyIcon },
    props: {
        // 完整数据
        dataAll: Array,
    },
    data() {
        return {
            list: [
                {
                    key: Date.now(),
                    pid: "",
                    content: "",
                    support: 1,
                    status: 1,
                    reason: "",
                },
            ],
        };
    },
    mounted() {},
    methods: {
        // 添加项
        add(el) {
            this.list.push({
                key: Date.now(),
                pid: el.pid,
                content: "",
                support: 1,
                status: 1,
                reason: "",
            });
        },
        // 删除项
        del(key) {
            if (this.list.length <= 1) return this.$message.error("请勿删除最后一项");
            this.list = this.list.filter(el => {
                return el.key !== key;
            });
        },
        // 提交
        async yes() {
            let list = JSON.parse(JSON.stringify(this.list));
            for (let i = 0; i < list.length; i++) {
                if (!list[i].pid) return this.$message.error("有一项没有选择分类，无法提交");
            }
            list.forEach(el => {
                el.pid = el.pid[1];
            });
            axios
                .post(
                    "/api/price/tadd",
                    qs.stringify({
                        ...list,
                    })
                )
                .then(() => {
                    this.$message.success("添加成功");
                    this.$confirm("是否继续添加?", "提示", {
                        confirmButtonText: "是",
                        cancelButtonText: "否",
                        type: "warning",
                    }).catch(() => {
                        this.$emit("stop");
                    });
                })
                .catch(rej => {
                    this.$message.error(rej);
                });
            this.close();
        },
        // 取消
        no() {
            this.$confirm("是否确认取消返回?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.close();
                this.$emit("stop");
            });
        },
        // 重置数据
        close() {
            this.list = [
                {
                    key: Date.now(),
                    pid: "",
                    content: "",
                    support: 1,
                    status: 1,
                    reason: "",
                },
            ];
        },
    },
    computed: {
        options() {
            return this.dataAll.map(el => {
                return {
                    label: el.name,
                    value: el.id,
                    children: el.second.map(e => {
                        return { label: e.name, value: e.id };
                    }),
                };
            });
        },
    },
};
</script>
<style scoped lang="less">
.add {
    .list {
        background-color: white;
        border-radius: 20px;
        padding: 40px;
        margin-top: 20px;
    }
}
</style>
