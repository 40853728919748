<template>
    <div class="edit">
        <div class="list">
            <template v-for="el in list">
                <addItem :options="options" :form="el" :key="el.id" @add="add(el)" @del="del"></addItem>
            </template>
            <!-- {{ list }} -->
        </div>
        <!-- 悬浮按钮 -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <VyIcon name="取消" scale="4" @click.native="no"></VyIcon>
        </div>
    </div>
</template>
<script>
import addItem from "./add_item.vue";
import axios from "axios";
import qs from "qs";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { addItem, VyIcon },
    props: {
        dataEdit: Array,
        dataAll: Array,
        pid: Number,
    },
    data() {
        return {
            list: [],
        };
    },
    methods: {
        // 确认
        async yes() {
            let list = JSON.parse(JSON.stringify(this.list));
            for (let i = 0; i < list.length; i++) {
                if (!list[i].pid) return this.$message.error("有一项没有选择分类，无法提交");
            }
            list.forEach(el => {
                el.pid = el.pid[1];
            });
            let { data } = await axios.put(
                "/api/price/tupdate?id=" + this.pid,
                qs.stringify({
                    ...list,
                })
            );
            if (data.code !== 200) return this.$message.error(data.msg);
            this.$message.success("添加成功!");
            this.no();
        },
        // 取消
        no() {
            this.$confirm("是否确认返回?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.list = [];
                this.$emit("stop");
            });
        },
        // 添加一项
        add(el) {
            this.list.push({
                key: Math.random(),
                pid: el.pid,
                content: "",
                support: 1,
                reason: "",
                status: 1,
            });
        },
        // 删除指定项
        del(key) {
            if (this.list.length <= 1) return this.$message.error("请勿删除最后一项");
            this.list = this.list.filter(el => {
                return el.key !== key;
            });
        },
    },
    computed: {
        options() {
            return this.dataAll.map(el => {
                return {
                    label: el.name,
                    value: el.id,
                    children: el.second.map(e => {
                        return { label: e.name, value: e.id };
                    }),
                };
            });
        },
    },
    watch: {
        dataEdit(newVal) {
            let l = newVal.map(el => {
                return { ...el, key: Math.random(), pid: [el.firstId, el.pid] };
            });
            l.push({
                key: Math.random(),
                pid: [...l[0].pid],
                content: "",
                support: 1,
                reason: "",
                status: 1,
            });
            this.list = l;
        },
    },
};
</script>
<style scoped lang="less">
.edit {
    .list {
        // background-color: white;
        background-color: white;
        border-radius: 20px;
        padding: 40px;
        margin-top: 20px;
    }
}
</style>
